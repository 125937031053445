<template>
	<div>
		<div class="grid-cols-3 gap-4">
			<headLeft class="col-span-2 h"/>
			<div class="as" v-show="$store.state.deviceType === 'mobile'"></div>
		</div>

		<div class="wrapper">
		<h1>Privacy Policy </h1>
		<p>
			At yoloplay.games, we respect your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your information.
		</p>
		<ol>
			<li>
				<strong>Information Collection:</strong>We may collect personal information such as your name and email address when you voluntarily provide it to us. We also collect non-personal information such as your IP address and browser type to help us improve our services.
			</li>
			<li>
				<strong>Use of Information:</strong>The information we collect is used to provide and improve our services, respond to your inquiries, and send you updates and promotional materials.
			</li>
			<li>
				<strong>Information Sharing:</strong>We do not sell, trade, or otherwise transfer your personal information to outside parties except as required by law.
			</li>
			<li>
				<strong>Cookies:</strong>We use cookies to enhance your experience on our website. You can choose to disable cookies through your browser settings, but this may affect your ability to use certain features of our site.
			</li>
			<li>
				<strong>Security:</strong>We implement a variety of security measures to protect your personal information. However, please be aware that no method of transmission over the internet is 100% secure.
			</li>
		</ol>
	</div>
	</div>

</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.wrapper {
	margin-top: rem(30);
	padding: rem(28) rem(30);
    color: #002b51;
    font-family: Roboto;
    font-style: normal;
    border-radius: rem(20);
    background: #FFF;
	box-shadow: rem(0) rem(5) rem(15) rem(0) rgba(0, 0, 0, 0.40);
	h1 {
		font-size: rem(40);
		font-weight: 900;
		line-height: rem(60); /* 150% */
		letter-spacing: rem(1);
	}
	p , li {
		font-size: rem(24);
		font-weight: 400;
		line-height: rem(50); /* 208.333% */
		letter-spacing: rem(1);
	}

}

</style>